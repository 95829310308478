// Step2SelectVehicle.ts
import {App} from './App';
import {Vehicle} from "./api/Vehicle";
import {Step3Extras} from './Step-3-Extras';
import {BookingSummaryDataType} from "./types/types";
import { getTranslation } from './translations';

export class Step2SelectVehicle {
    private vehicleApi = new Vehicle();

    constructor(formData: FormData) {
        this.renderVehiclesList(formData).then(
            () => {
                App.changeButtonState(App.bookingSubmitButton, 'enabled',  getTranslation((window as any).langValue, 'Search'));
            }
        )

    }

    public async renderVehiclesList(formData: FormData): Promise<void> {
        App.summaryData = this.extractFormData(formData);

        try {
            const vehiclesHTML = await this.getAvailableVehicles(formData);
            if (App.vehiclesListContainer && vehiclesHTML)
            {
                App.vehiclesListContainer.innerHTML = vehiclesHTML;
                App.showStepContainer(2);

                this.attachEventListenersToButtons();

                await App.swiperSteps.show('step1');
                App.update(App.summaryData);
                App.topScroll?.scrollIntoView({behavior: 'smooth', block: 'start'});
                App.swiperHandler.nextSlide();
            }
        } catch (error) {
            console.error("Error processing form submission:", error);
            App.changeButtonState(App.bookingSubmitButton, 'enabled');
        }
    }

    private async getAvailableVehicles(formData: FormData): Promise<string | undefined> {
        App.hideErrors();

        formData.append('nonce', App.nonce);
        const response = await App.APIHandler.postData({
            url: App.checkVehicleAvailabilityEndpoint,
            data: formData
        });
        const vehicles = JSON.parse(response);

        if (!vehicles.success) {
            console.error('Vehicle error:', vehicles.data.message);
            App.showError(vehicles.data.message);
            // @ts-ignore
            App.changeButtonState(App.bookingSubmitButton, 'enabled');
            return;
        }
        else {
            const vehiclesList = vehicles.data;
            if (vehiclesList && typeof vehiclesList === 'string') {
                return this.getAvailableVehiclesHTML(vehiclesList);
            } else if (vehiclesList && Array.isArray(vehiclesList)) {
                // @ts-ignore
                return this.getAvailableVehiclesHTML(vehiclesList);
            } else {
                App.showError(getTranslation((window as any).langValue, 'noVehiclesAvailable'));
            }
        }

    }

    private extractFormData(formData: FormData): BookingSummaryDataType {
        return {
            vehicleName: '',
            vehicleCategory: '',
            vehicleImage: '',
            pickupDate: formData.get('date_from') as string,
            pickupTime: formData.get('time_from') as string,
            returnDate: formData.get('date_to') as string,
            returnTime: formData.get('time_to') as string,
            pickupLocation: formData.get('from') as string,
            returnLocation: formData.get('to') as string,
            totalDays: 0,
            price: 0,
            extras: [
                {id: '', name: '', quantity: 0, pricePerDay: 0}
            ]
        };
    }

    private async getAvailableVehiclesHTML(vehicles: string): Promise<string | undefined> {
        try {
            const vehiclesHTMLJSON = await App.APIHandler.postData({
                url: App.getVehiclesByCategoryEndpoint,
                data: {vehicles}
            });
            const vehiclesHTML = JSON.parse(vehiclesHTMLJSON).data;
            if (vehiclesHTML && typeof vehiclesHTML === 'string') {
                return vehiclesHTML;
            } else {
                console.error('Unexpected response format when getting category vehicles');
            }
        } catch (error) {
            console.error('Error in getAvailableVehiclesHTML: ' + error);
            App.showError('Error in getAvailableVehiclesHTML: ' + error);
        }
    }

    private attachEventListenersToButtons(): void {
        const buttons = document.querySelectorAll('.get-extras-btn');
        buttons.forEach(button => {
            button.addEventListener('click', (event) => this.handleVehicleSelection(event));
        });
    }

    private async handleVehicleSelection(event: Event) {
        const target = event.target as HTMLElement;

        if (!target) {
            return;
        }

        App.changeButtonState(<HTMLButtonElement>event.target, 'loading', getTranslation((window as any).langValue, 'loading'));
        const vehicleId = target.getAttribute('data-category');
        const vehiclePrice = parseFloat(<string>target.getAttribute('data-price'));

        if (!vehicleId) {
            console.error('Error in handleVehicleSelection');
            App.showError('handleVehicleSelection');
            return;
        }

        try {
            const vehicleDetails = await this.vehicleApi.getVehicleDetails(vehicleId);
            // @ts-ignore
            App.summaryData['vehicleImage'] = vehicleDetails['image'];
            // @ts-ignore
            App.summaryData['vehicleName'] = vehicleDetails['name'];

            await App.swiperHandler.goToSlide(3);

            App.topScroll?.scrollIntoView({behavior: 'smooth', block: 'start'});
            App.summaryData.vehicleCategory = vehicleId;
            if (typeof vehicleDetails === "string") {
                // @ts-ignore
                App.summaryData.vehicleName = vehicleDetails['name'];
            }
            // @ts-ignore
            App.summaryData.price = vehiclePrice;
            App.summaryTotalPrice = parseFloat(String(vehiclePrice));
            // await this.step3Extras.getExtrasHTMLFromAPI();

            if ( App.maxStep < 2 ) {
                App.maxStep = 2;
            }

            // console.log('Change button state to enabled and text to Reservar ahora');
            App.changeButtonState(<HTMLButtonElement>event.target, 'enabled', getTranslation((window as any).langValue, 'bookNow'));
            App.step2Container.innerHTML = await App.swiperSteps.render('step2');

            new Step3Extras();

        } catch (error) {
            console.error('Error fetching vehicle details handleVehicleSelection: ' + error);
            App.showError('Error fetching vehicle details handleVehicleSelection: ' + error);
        }
    }

}
