import {App} from "./App";
import {APIHandler} from './api/APIHandler';
import { RenderSwiperSteps } from './handlers/RenderSwiperSteps'
import {Step4DriverDataForm} from "./Step-4-DriverDataForm";
import {getTranslation} from "./translations";

const apiHandlerInstance = new APIHandler(App.apiBaseUrl, App.nonce);

export class Step3Extras {
    renderSwiper = new RenderSwiperSteps();
    constructor() {
        this.attachEventListenerToExtrasForm();
        App.extrasContainer.classList.remove('hidden');
        this.getExtrasFormHTMLFromAPI() // Fetch extras from API
        this.renderSwiper = new RenderSwiperSteps();

    }

    private attachEventListenerToExtrasForm(): void {
        if (App.extrasForm) {
            // console.log('Event listener attached to extras form.');
            App.extrasForm.addEventListener('submit', (event) => this.handleExtrasFormSubmit(event));
        } else {
            console.error('Extras form not found');
        }
    }

    private async handleExtrasFormSubmit(event: Event): Promise<void> {
        event.preventDefault();
        App.changeButtonState(App.extrasSubmitButton, 'loading', getTranslation((window as any).langValue, 'savingExtras'));
        const extras = this.collectExtras();
        await this.updateSummary(extras);
        App.showStepContainer(4);
        App.changeButtonState(App.extrasSubmitButton, 'enabled', getTranslation((window as any).langValue, 'saveExtras'));

        new Step4DriverDataForm();

    }

    private async getExtrasFormHTMLFromAPI(): Promise<void> {
        const formData = new FormData();
        try {
            formData.append('category', App.summaryData.vehicleCategory);
            formData.append('from', String(App.summaryData.pickupLocation));
            formData.append('to', String(App.summaryData.returnLocation));
            formData.append('total_days', String(App.summaryData.totalDays));
            formData.append('time_from', App.summaryData.pickupTime);
            formData.append('time_to', App.summaryData.returnTime);

            const response = await apiHandlerInstance.postData({
                url: App.getExtras,
                data: formData,
            });

            // @ts-ignore
            App.extrasDataContainer.innerHTML = JSON.parse(response).data;
            App.extrasDataContainer.classList.remove('hidden');

        } catch (error) {
            App.showError('Error fetching extras. Please try again.');
        }
    }

    private collectExtras(): any[] {
        let extras: any[] = [];
        const selectElements = document.querySelectorAll('select[name^="extras["]');

        selectElements.forEach(selectElement => {
            const extrasSelect = selectElement as HTMLSelectElement;
            // @ts-ignore
            const extraId = extrasSelect.name ? extrasSelect.name.match(/\[(.*?)]/)[1] : undefined;  // Extract extra ID
            const selectedOption = extrasSelect.options[extrasSelect.selectedIndex];
            const quantity = parseInt(extrasSelect.value, 10);

            if (quantity > 0) {
                const name = selectedOption.getAttribute('data-name') || 'Unknown Extra'; // Default to 'Unknown Extra' if data-name is not set
                const pricePerDay = parseFloat(selectedOption.getAttribute('data-day-price') || '0');
                extras.push({ id: extraId, name: name, quantity: quantity, pricePerDay: pricePerDay });
            }
        });

        return extras;
    }

    private async updateSummary(extras: any[]): Promise<void> {
        let totalPriceExtras: number = 0;

        extras.forEach(extra => {
            totalPriceExtras += extra['pricePerDay'] * extra['quantity'];
        });

        App.summaryData.extras = extras;
        App.summaryData.price = (App.summaryData.price || 0) + totalPriceExtras;
        // console.log('summaryData', App.summaryData);

        App.step3Container.innerHTML = await App.swiperSteps.render('step3');

        // It goes to App.ts
        await App.swiperHandler.goToSlide(4);

        if ( App.maxStep < 4 ) {
            App.maxStep = 4;
        }

    }

}
