import {Step2SelectVehicle} from "./Step-2-SelectVehicle";
import {App} from "./App";
import { getTranslation } from './translations';

const lang = (window as any).langValue;

export class Step1DatesAndOffices {

    constructor() {
        this.attachEventListeners();
    }

    private attachEventListeners(): void {
        let mainForm: HTMLFormElement;
        mainForm = App.formContainer as HTMLFormElement;
        if (mainForm) {
            mainForm.addEventListener('submit', this.datesLocalizationsSubmit.bind(this));
        }
    }

    private async datesLocalizationsSubmit(event: Event): Promise<void> {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
        App.changeButtonState(App.bookingSubmitButton, 'loading', getTranslation(lang, 'checkingAvailability'));
        new Step2SelectVehicle(formData);
        if ( App.maxStep < 1 ) {
            App.maxStep = 1;
        }
    }
}
