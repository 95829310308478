export const messages = {
    en: {
        Search: 'Search',
        'checkingAvailability': 'Checking availability',
        bookNow: 'Book now',
        loading: 'Loading',
        noVehiclesAvailable: 'We do not have any vehicles available with your search parameters',
        checkVehicles: 'Check vehicles',
        saveExtras: 'Save Extras',
        savingExtras: 'Saving extras',
        fetchExtrasError: 'Error fetching extras. Please try again.',
        reserveNow: 'Book',
        vehicleDetailsError: 'Error fetching vehicle details handleVehicleSelection',
        bookingFailed: 'Failed to save booking',
        paymentLinkFailed: 'Failed to create payment link',
        fetchPaymentLinkError: 'Error fetching payment link',
        checkingStep1: '1. Zones and rental period',
        checkingStep2: '2. Vehicle',
        checkingStep3: '3. Extras',
        vehicleLabel: 'Vehicle: ',
        totalPriceLabel: 'Total price: ',
        totalLabel: 'Total: ',
    },
    es: {
        Search: 'Buscar',
        'checkingAvailability': 'Comprobando disponibilidad',
        bookNow: 'Reservar',
        loading: 'Cargando',
        noVehiclesAvailable: 'No tenemos ningún vehículo disponible con sus parámetros de búsqueda',
        checkVehicles: 'Ver vehículos',
        saveExtras: 'Guardar Extras',
        savingExtras: 'Guardando',
        fetchExtrasError: 'Error fetching extras. Please try again.',
        reserveNow: 'Reservar ahora',
        vehicleDetailsError: 'Error fetching vehicle details handleVehicleSelection',
        bookingFailed: 'Failed to save booking',
        paymentLinkFailed: 'Failed to create payment link',
        fetchPaymentLinkError: 'Error fetching payment link',
        checkingStep1: '1. Zonas y periodo alquiler',
        checkingStep2: '2. Vehículo',
        checkingStep3: '3. Extras',
        vehicleLabel: 'Vehículo: ',
        totalPriceLabel: 'Precio total: ',
        totalLabel: 'Total: ',
    }
};

export function getTranslation(lang: string | undefined, key: string): string {
    let safeLang = lang || 'en'; // Default to 'en' if lang is undefined

    if ( lang === 'es-ES' ) {
        safeLang = 'es';
    }

    if ( lang === 'en-US' ) {
        safeLang = 'en';
    }

    if ( lang === 'en-GB' ) {
        safeLang = 'en';
    }

    // Verifica que safeLang es un idioma válido en messages
    if (!messages[safeLang]) {
        console.error(`Idioma no válido: ${safeLang}`);
        return key;
    }

    // Verifica que la clave existe en el idioma especificado
    if (!messages[safeLang][key]) {
        console.error(`Clave no encontrada para ${safeLang}: ${key}`);
        return key;
    }

    return messages[safeLang][key];
}
