// main.ts

import {Step1DatesAndOffices} from "./bookingManager/Step-1-DatesAndOffices";
import {Step4DriverDataForm} from "./bookingManager/Step-4-DriverDataForm";
import {App} from "./bookingManager/App";


/**
 * Application entrypoint
 */
document.addEventListener('DOMContentLoaded', () => {
    new Step1DatesAndOffices();
});


// (function(window) {
//     window.onload = function() {
//         window.history.pushState(null, "No back", "nobackpage");
//         window.onpopstate = function() {
//             window.history.pushState(null, "No back", "nobackpage");
//         };
//     };