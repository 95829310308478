import {App} from './App';
import {APIHandler} from './api/APIHandler';
// import { RenderSwiperSteps } from './handlers/RenderSwiperSteps'
import {Office} from "./api/Office";
import {getTranslation} from "./translations";

const apiHandlerInstance = new APIHandler(App.apiBaseUrl, App.nonce);

export class Step4DriverDataForm {

    private office: Office;
    private payLink: string;
    private sessionID: string;

    constructor() {
        this.payLink = '';
        this.sessionID = '';
        this.office = new Office();
        App.bookingForm.addEventListener('submit', (event) => this.handleBookingFormSubmit(event));
        App.bookingForm.classList.remove('hidden');
        App.changeButtonState(App.bookingSubmitButton, 'enabled', getTranslation((window as any).langValue, 'bookNow'));
        // @ts-ignore
        App.topScroll.scrollIntoView({behavior: 'smooth'});
        this.setFormData();
        this.initPayLink(); // Initialize the payment link
    }

    private async initPayLink(): Promise<void> {
        this.payLink = await this.getPaidLink();
    }

    public saveBooking(): void {
        // Save booking data to WordPress database
        const formData = new FormData();
        formData.append('action', 'save_booking_endpoint');
        formData.append('data', JSON.stringify(App.summaryData));
        console.log("saveBooking");
        apiHandlerInstance.postData({
            url: App.saveBookingEndpoint,
            data: formData
        }).then(response => {
            const responseJSON = JSON.parse(response);
            if (!responseJSON.success) {
                console.error('Failed to save booking');
            }
        }).catch(error => {
            console.error('Error saving booking: ' + error);
        });
    }


    public async handleBookingFormSubmit(event: Event): Promise<void> {
        event.preventDefault();

        // this.saveBooking();
        console.log("saveBooking");
        const form = document.getElementById('bookingFormId') as HTMLFormElement;
        const formData = new FormData(form);

        try {
            const response = await App.APIHandler.postData({
                url: App.bookingSubmitEndpoint,
                data: formData
            });

            const responseJSON = JSON.parse(response);

            if (responseJSON.success) {
                // Ensure payLink is initialized
                if (!this.payLink) {
                    this.payLink = await this.getPaidLink();

                }
                if (this.payLink) {
                    window.location.href = this.payLink;
                } else {
                    console.error('Failed to create payment link');
                    App.showError('Failed to create payment link');
                }
            } else {
                console.error('Failed to save booking');
                App.showError('Failed to save booking');
            }
        } catch (error) {
            console.error('Error in handleBookingFormSubmit: ' + error);
            App.showError('Error in handleBookingFormSubmit');
        }
    }

    private async getPaidLink(): Promise<string> {
        const formData = new FormData();

        try {
            formData.append('action', 'create_stripe_payment_link');
            formData.append('amount', App.summaryData.price.toString());

            const response = await apiHandlerInstance.postData({
                url: '',
                data: formData,
            });

            const result = JSON.parse(response);

            if (result.success) {
                console.log(result.data);
                console.log('Session ID: ' + result.data.id);
                this.sessionID = result.data.id;
                const finalBookingSessionId = document.getElementById('finalBookingSessionId') as HTMLInputElement;
                finalBookingSessionId.value = this.sessionID;
                console.log('added session id to form: ' + this.sessionID);
                return result.data.url;
            } else {
                // console.log('Error creating payment link: ' + result.data);
                return '';
            }

        } catch (error) {
            // console.log('Error creating payment link');
            App.showError('Error fetching payment link');
        }

        return '';

    }

    private async setFormData(): Promise<void> {
        if (!App.summaryData) {
            return;
        }

        // @ts-ignore
        document.getElementById('finalBookingCarImage').src = App.summaryData.vehicleImage;

        const finalBookingFromDate = document.getElementById('finalBookingFromDate') as HTMLInputElement;
        const finalBookingFromDateInput = document.getElementById('finalBookingFromDateInput') as HTMLInputElement;
        const finalBookingFromTime = document.getElementById('finalBookingFromTime') as HTMLInputElement;
        const finalBookingFromTimeInput = document.getElementById('finalBookingFromTimeInput') as HTMLInputElement;
        const finalBookingFromLocation = document.getElementById('finalBookingFromLocation') as HTMLInputElement;
        const finalBookingFromLocationInput = document.getElementById('finalBookingFromLocationInput') as HTMLInputElement;
        const finalBookingToDate = document.getElementById('finalBookingToDate') as HTMLSpanElement;
        const finalBookingToDateInput = document.getElementById('finalBookingToDateInput') as HTMLInputElement;
        const finalBookingToTime = document.getElementById('finalBookingToTime') as HTMLSpanElement;
        const finalBookingToTimeInput = document.getElementById('finalBookingToTimeInput') as HTMLInputElement;
        const finalBookingToLocation = document.getElementById('finalBookingToLocation') as HTMLSpanElement;
        const finalBookingToLocationInput = document.getElementById('finalBookingToLocationInput') as HTMLInputElement;

        const finalBookingExtrasContainer = document.getElementById('finalBookingExtrasContainer') as HTMLDivElement;

        const finalBookingCar = document.getElementById('finalBookingCar') as HTMLSpanElement;
        const finalBookingCarCategoryId = document.getElementById('finalBookingCarCategoryId') as HTMLInputElement;
        const finalBookingExtras = document.getElementById('finalBookingExtras') as HTMLSpanElement;
        const finalBookingExtrasInput = document.getElementById('finalBookingExtrasInput') as HTMLInputElement;
        const finalBookingCarNameInput = document.getElementById('finalBookingCarNameInput') as HTMLInputElement;

        const finalBookingTotalPrice = document.getElementById('finalBookingTotalPrice') as HTMLSpanElement;
        const finalBookingTotalPriceInput = document.getElementById('finalBookingTotalPriceInput') as HTMLInputElement;

        finalBookingFromDate.textContent = App.summaryData.pickupDate;
        finalBookingFromDateInput.value = App.summaryData.pickupDate;
        finalBookingFromTime.textContent = App.summaryData.pickupTime;
        finalBookingFromTimeInput.value = App.summaryData.pickupTime;

        finalBookingFromLocation.textContent = <string>await this.office.getOfficeDetails(App.summaryData.pickupLocation);
        finalBookingFromLocationInput.value = App.summaryData.pickupLocation;
        finalBookingToLocation.textContent = <string>await this.office.getOfficeDetails(App.summaryData.returnLocation);
        finalBookingToLocationInput.value = App.summaryData.returnLocation

        finalBookingToDate.textContent = App.summaryData.returnDate;
        finalBookingToDateInput.value = App.summaryData.returnDate;
        finalBookingToTime.textContent = App.summaryData.returnTime;
        finalBookingToTimeInput.value = App.summaryData.returnTime;

        finalBookingCar.textContent = App.summaryData.vehicleName;
        finalBookingCarCategoryId.value = App.summaryData.vehicleCategory;
        finalBookingCarNameInput.value = App.summaryData.vehicleName;

        if (App.summaryData.extras.length > 0) {
            finalBookingExtrasContainer.classList.remove('hidden');
        }
        finalBookingExtras.textContent = App.summaryData.extras.map(extra => extra.name).join(', ');
        finalBookingExtrasInput.value = App.summaryData.extras.map(extra => extra.id).join('-|-');

        finalBookingTotalPrice.textContent = App.summaryData.price.toString() + '€';
        finalBookingTotalPriceInput.value = App.summaryData.price.toString();

        const bookingFinalFormButton = document.getElementById('bookingFinalFormButtonId') as HTMLAnchorElement;


        bookingFinalFormButton.addEventListener(
            'click',
            (event) => {
                event.preventDefault();
                this.handleBookingFormSubmit( event );
                window.location.href = this.payLink;
            }
        )
    }
}
