// RenderSwiperSteps.ts
import {App} from '../App';
import {Office} from "../api/Office";
import { getTranslation } from '../translations';
import {SwiperHandler} from './SwiperHandler';

const lang = (window as any).langValue;


export class RenderSwiperSteps {
    private summaryElement: HTMLElement;
    private office: Office;
    public swiper: SwiperHandler;

    constructor() {
        this.summaryElement = App.step1Container;
        this.office = new Office();
        this.swiper = new SwiperHandler;
    }

    public async show(step: string = "step1"): Promise<void> {
        this.summaryElement.innerHTML = await this.render(step);
        this.summaryElement.classList.remove('hidden');
    }

    public hide(): void {
        this.summaryElement?.classList.add('hidden');
    }

    public async render(step: string): Promise<string> {
        // console.log('Render step:', step);
        this.setTotalPrice();

        switch (step) {
            case 'step1':
                return await this.renderSwiperStep1();
            case 'step2':
                return this.renderSwiperStep2();
            case 'step3':
                return this.renderSwiperStep3();
            case 'step4':
                return this.renderSwiperStep4();
            case 'step5':
                return this.renderSwiperStep5();
            default:
                return '';
        }
    }

    // public update(update: Partial<BookingSummaryDataType>): void {
    //     Object.assign(this.summaryElement, update);
    // }

    /**
     * Particular renders
     */
    private async renderSwiperStep1(): Promise<string> {
        const options: Intl.DateTimeFormatOptions = {month: 'long', day: 'numeric'};
        const pickupDate = new Date(App.summaryData.pickupDate).toLocaleDateString("es-ES", options);
        const returnDate = new Date(App.summaryData.returnDate).toLocaleDateString("es-ES", options);
        const pickupLocationName = await this.office.getOfficeDetails(App.summaryData.pickupLocation);
        const returnLocationName = await this.office.getOfficeDetails(App.summaryData.returnLocation);

        return (
            '<h2 class="text-3xl" style="margin-bottom: 15px;">' + 
            getTranslation(lang, 'checkingStep1') + 
            '<i class="fas fa-check"></i></h2>' +
            '<div class="booking-summary py-4 pb-2">' +
            '<div class="flex flex-row justify-center booking-summary__data">' +
            '<div class="inline-block align-middle">' +
            '<ul><li>' +
            '<span>' + pickupLocationName + '</span>' +
            '</li><li>' +
            '<span> ' + pickupDate + ' ' + App.summaryData.pickupTime + '</span>' +
            '</li></ul>' +
            '</div>' +
            '<div class="step1__arrow inline-block align-middle pt-10 lg:pt-0"><br><i class="fas fa-arrow-right"></i></div>' +
            '<div class="inline-block align-middle">' +
            '<ul><li>' +
            '<span>' + returnLocationName + '</span>' +
            '</li>' +
            '<span>' + returnDate + ' ' + App.summaryData.returnTime + '</span>' +
            '</li></ul>' +
            '</div></div></div></div>'
        );

    }

    private renderSwiperStep2(): string {
        return (
            '<h2 class="text-3xl">' + 
            getTranslation(lang, 'checkingStep2') + 
            '<i class="fas fa-check"></i></h2>' +
            '<div class="flex-col justify-between">' +
            '<span>' + App.summaryData.vehicleName + '</span>' +
            '<span><img src="' + App.summaryData.vehicleImage + '" alt="' + App.summaryData.vehicleName + '"></span>' +
            '</div>'
        );
    }

    private renderSwiperStep3(): string {
        return this.updateExtrasStepHTML();
    }

    private updateExtrasStepHTML(): string {

        const extrasHtml = App.summaryData.extras.map((extra: any) => {
            return `<li class="extra-item">${extra.name}</li>`;
        }).join('');


        if (!App.summaryData.extras || App.summaryData.extras.length === 0) {
            return `<div class="flex-col justify-between h-auto">` +
                `<h2 class="text-3xl">3. Extras <i class="fas fa-check"></i></h2>` +
                `</div>`;
        }

        return  `<div class="flex-col justify-between h-auto">` +
            `<h2 class="text-3xl">3. Extras <i class="fas fa-check"></i></h2>` +
            `<ul class="step-extras-list">${extrasHtml}</ul>` +
            `</div>`;

        // if (App.step3Container) {
        //     App.step3Container.innerHTML =
        //         `<div class="flex-col justify-between h-auto">` +
        //         `<h2 class="text-3xl">3. Extras <i class="fas fa-check"></i></h2>` +
        //         `<p>${App.summaryData.vehicleName}</p>` +
        //         `<ul class="step-extras-list">${extrasHtml}</ul>` +
        //         `</div>`;
        //
        //     console.log('innerHTML', App.step3Container.innerHTML);
        // } else {
        //     console.error('Step 3 container not found');
        // }
    }



    private renderSwiperStep4(): string {
        return (
            '<div class="flex-col justify-between">' +
            '<span>' + 
            getTranslation(lang, 'vehicleLabel') +
            ': </span>' +
            '<span>' + App.summaryData.vehicleName + '</span>' +
            '</div>'
        );
    }

    private renderSwiperStep5(): string {
        return (
            `<div class="flex-col justify-between">
            <span>`
            + getTranslation(lang, 'vehicleLabel') +
            `${App.summaryData.vehicleName}</span>
            <span>Precio total: ${App.summaryTotalPrice}€</span>
            </div>`
        );
    }

    private setTotalPrice(): void {
        App.calculateTotalPrice();
        App.step5Container.innerHTML = `<span>Total: ${App.summaryTotalPrice}€</span>`;
    }

}
